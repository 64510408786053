html {
  overflow: auto;
}

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
  background-image: url('./assets/bg_pattern.svg');
  background-repeat: repeat;
  padding: 0;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*{
  box-sizing: border-box;
}

section {
  width: 100%;
  display: block;
}

a {
  text-decoration: none;
}

/* Remove arrows from inputs with type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.grecaptcha-badge{
  display: none!important;
}

.MuiPickersDay-root:hover,.MuiPickersDay-root:focus,.Mui-selected{
  background-color: #FF9900!important;
  color: white!important;
}

.MuiInputBase-input{
  font-weight: 700!important;
}